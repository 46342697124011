.presets-container {
    padding: 16px;
    margin-top: 16px;
    border-top: 1px solid #eee;
}

.save-preset-btn, .load-preset-btn {
    padding: 8px 16px;
    margin: 4px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    transition: all 0.2s ease;
}

.save-preset-btn:hover, .load-preset-btn:hover {
    background-color: #f8f9fa;
    border-color: #ccc;
}

.save-preset-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.saved-presets {
    margin-top: 16px;
}

.saved-presets h3 {
    font-size: 16px;
    margin-bottom: 8px;
    color: #666;
}

.preset-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.recommendations-header {
    text-align: center;
    margin-bottom: 20px;
}

.recommendations-header h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

.start-search-container {
    text-align: center;
    margin: 20px 0;
}

.start-search-btn {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
    outline: none;
    background-color: #1976D2;
    color: white;
    border-radius: 4px;
}

.start-search-btn:hover {
    background-color: #1565C0;
}

.loading-bar-container {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  text-align: center;
  padding: 20px;
}

.loading-bar {
  width: 100%;
  height: 4px;
  background-color: #f0f0f0;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
}

.loading-progress {
  position: absolute;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #2196F3;
  animation: loading 1.5s ease infinite;
}

.loading-message {
  margin-top: 10px;
  color: #666;
  font-size: 14px;
}

@keyframes loading {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

.progress-bar {
    width: 100%;
    height: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px 0;
}

.progress-bar-fill {
    height: 100%;
    background-color: #4CAF50;
    transition: width 0.3s ease-in-out;
}

.loading-container {
    text-align: center;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
}

.loading-container p {
    margin-top: 10px;
    color: #666;
}
