.search-overview {
    height: 100%;
    background: white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.search-overview-content {
    max-width: 800px;
    width: 100%;
    text-align: center;
}

.search-overview-header {
    margin-bottom: 3rem;
}

.search-overview-header h2 {
    font-size: 2rem;
    color: #2D3748;
    margin: 1rem 0;
}

.search-icon {
    font-size: 2.5rem;
    color: #6b46c1;
    margin-bottom: 1rem;
}

.feature-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-bottom: 3rem;
}

.feature-card {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
}

.feature-card.clickable {
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.feature-card:hover {
    transform: translateY(-5px);
}

.feature-card.clickable:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.feature-icon {
    font-size: 2rem;
    color: #6b46c1;
    margin-bottom: 1rem;
}

.feature-card h3 {
    color: #2D3748;
    margin-bottom: 0.5rem;
}

.feature-card p {
    color: #4A5568;
    line-height: 1.6;
}

.search-overview-footer {
    margin-top: 2rem;
}

.sign-in-prompt {
    background: white;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.sign-in-prompt p {
    color: #4A5568;
    margin-bottom: 1rem;
}

.sign-in-button {
    background-color: #6b46c1;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sign-in-button:hover {
    background-color: #553c9a;
}

.swipe-instruction {
    color: #4A5568;
    font-size: 1.1rem;
}

.swipe-animation {
    margin-top: 1rem;
}

.swipe-arrow {
    display: inline-block;
    font-size: 1.5rem;
    animation: swipeRight 1.5s infinite;
}

@keyframes swipeRight {
    0%, 100% {
        transform: translateX(0);
        opacity: 0;
    }
    50% {
        transform: translateX(20px);
        opacity: 1;
    }
}
