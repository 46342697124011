/* Login.css */

/* General Page Styles */
body {
    font-family: 'Arial', sans-serif;
    background: white;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

/* Scoped container for the login page */
.login-container {
    background: #e8e0ff;
    backdrop-filter: blur(10px) saturate(150%);
    padding: 2.5rem;
    border-radius: 12px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.2);
    animation: fadeIn 1s ease-in-out;
}

/* Add a subtle animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Brand Header */
.brand-header {
    margin-bottom: 2rem;
}

.brand-header h1 {
    color: #2D3748;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.brand-header p {
    color: #718096;
    font-size: 1rem;
}

/* Login Form */
.login-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form-group {
    text-align: left;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #4A5568;
    font-size: 0.9rem;
    font-weight: 500;
}

.form-group input[type="email"],
.form-group input[type="password"] {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 8px;
    font-size: 1rem;
    background: rgba(255, 255, 255, 0.8);
    color: #2D3748;
    transition: border-color 0.2s, background 0.2s;
}

.form-group input[type="email"]:focus,
.form-group input[type="password"]:focus {
    outline: none;
    border-color: #80d8ff;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 3px rgba(128, 216, 255, 0.3);
}

/* Button styles */
.login-button {
    background-color: #6B46C1;
    color: white;
    padding: 0.75rem;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.2s;
}

.login-button:hover {
    background-color: #553C9A;
    transform: scale(1.05);
}

/* Link to sign up */
.signup-link {
    margin-top: 1.5rem;
    color: #718096;
    font-size: 0.9rem;
}

.signup-link a {
    color: #6B46C1;
    text-decoration: none;
    font-weight: 500;
}

.signup-link a:hover {
    text-decoration: underline;
}

/* Google login button */
.login-container .google-login {
    background: #db4437;
    color: #fff;
    font-size: 1rem;
    margin-top: 10px; /* Space above the Google login button */
    border: none;
    border-radius: 8px;
    padding: 0.75rem;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.2s;
}

.login-container .google-login:hover {
    background: #c13325;
    transform: scale(1.05);
}

/* Error message styles */
.login-container .error {
    color: #e74c3c;
    font-size: 0.9rem;
    margin: 10px 0;
    text-align: center;
}
