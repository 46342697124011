.swipeable-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #f8f9fa;
}

.hover-zone {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 60px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all;
}

.hover-zone.left {
    left: 0;
}

.hover-zone.right {
    right: 0;
}

.view-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #f8f9fa;
}

.view-content {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
    background-color: #f8f9fa;
}

.view-content.active {
    opacity: 1;
    pointer-events: all;
    background-color: #f8f9fa;
}

.prev-view-button,
.next-view-button {
    opacity: 0;
    background: linear-gradient(135deg, #6b46c1, #9f7aea);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.hover-zone:hover .prev-view-button,
.hover-zone:hover .next-view-button {
    opacity: 1;
}

.prev-view-button:hover,
.next-view-button:hover {
    transform: scale(1.1);
    background: linear-gradient(135deg, #553c9a, #805ad5);
}

.navigation-arrows {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
    pointer-events: none;
    z-index: 10;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.swipeable-container:hover .navigation-arrows {
    opacity: 1;
}

.pagination-dots {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 0.5rem;
    z-index: 10;
}

.pagination-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgba(107, 70, 193, 0.3);
    cursor: pointer;
    transition: all 0.3s ease;
}

.pagination-dot.active {
    background-color: #6b46c1;
    transform: scale(1.2);
}

/* Responsive design */
@media (max-width: 768px) {
    .prev-view-button,
    .next-view-button {
        font-size: 1.5rem;
    }

    .pagination-dots {
        bottom: 0.5rem;
    }
}
