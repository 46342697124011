.job-recommendations {
    padding: 20px;
    width: 100%;
}

.recommendations-header {
    text-align: center;
    margin-bottom: 30px;
}

.recommendations-header h1 {
    font-size: 2em;
    color: #1a73e8;
    margin-bottom: 10px;
}

.recommendations-header p {
    font-size: 1.1em;
    color: #5f6368;
    margin-bottom: 25px;
}

.recommendation-controls {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.recommendation-type-select {
    padding: 12px 24px;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 1px solid #1a73e8;
    outline: none;
    background-color: white;
    color: #1a73e8;
    min-width: 200px;
}

.recommendation-type-select:focus {
    border-color: #1a73e8;
    box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.1);
}

.search-button {
    padding: 12px 24px;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
    outline: none;
    background-color: #1976D2;
    color: white;
}

.search-button:hover:not(:disabled) {
    background-color: #1557b0;
}

.search-button:focus {
    outline: none;
}

.search-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.progress-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 0 20px;
}

.progress-bar {
    width: 100%;
    height: 4px;
    background-color: #e8f0fe;
    border-radius: 2px;
    overflow: hidden;
}

.progress-fill {
    height: 100%;
    background-color: #1a73e8;
    transition: width 0.3s ease;
}

.no-search-message {
    text-align: center;
    padding: 40px;
    color: #5f6368;
    font-size: 1.1em;
    background-color: #f8f9fa;
    border-radius: 8px;
    margin: 20px auto;
    max-width: 600px;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
    .recommendation-controls {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }

    .recommendation-type-select,
    .search-button {
        width: 100%;
        min-width: 200px;
    }
}
