/* Home.css */

/* Main container with increased padding at the top */
.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding: 2rem;
    background-color: #ffffff;
}

/* Navbar */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw; /* Full viewport width */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    background-color: #4a4a4a;
    color: white;
    z-index: 1000; /* Ensure navbar stays above other content */
}

.navbar-title {
    font-size: 1.75rem;
    font-weight: 700;
    cursor: pointer;
}

.navbar-links {
    display: flex;
    gap: 1.5rem;
}

.navbar-link {
    background: none;
    border: none;
    color: white;
    font-size: 1.25rem;
    cursor: pointer;
    font-weight: 500;
    transition: color 0.2s;
}

.navbar-link:hover {
    color: #ddd;
}

/* Header */
.home-header {
    width: 100%;
    padding: 32px 0;
    font-size: 2rem;
    font-weight: 700;
    background-color: #333333;
    color: white;
}

/* Main content */
.home-main {
    display: flex;
    flex-direction: column;
    flex-grow:1;
    align-items: center;
    gap: 3rem;
    margin-top: 4rem;
    width: 100%;
    max-width: 1024px;
}

.home-heading {
    font-size: 4rem;
    font-weight: bold;
    color: #1f2937;
}

.home-button {
    background: linear-gradient(to right, #7c3aed, #3b82f6);
    color: white;
    padding: 16px 48px;
    border-radius: 0.75rem;
    font-size: 1.5rem;
    font-weight: 600;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    transition: transform 0.2s;
    cursor: pointer;
}

.home-button:hover {
    transform: scale(1.1);
}

.home-text {
    font-size: 1.5rem;
    color: #6b7280;
}

.welcome-section {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 2rem;
}

.title-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 2rem;
    margin-top: 4rem;
    margin-bottom: 2rem;
}

.welcome-content {
    text-align: center;
    padding: 0 2rem;
}

.welcome-content h1 {
    font-size: 2.5rem;
    color: #2d3748;
    margin-bottom: 1rem;
}

.welcome-content p {
    font-size: 1.2rem;
    color: #4a5568;
}

.decorative-line {
    flex: 1;
    height: 2px;
    background: linear-gradient(90deg, transparent, #6b46c1, transparent);
    max-width: 200px;
}

.welcome-user {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    margin-top: 3rem;
}

.greeting {
    font-size: 2rem;
    color: #333;
    margin: 0;
}

.get-started-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    font-size: 1.1rem;
    font-weight: 600;
    color: white;
    background: linear-gradient(135deg, #8a2be2 0%, #4a90e2 100%);
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.get-started-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(138, 43, 226, 0.2);
}

.arrow-icon {
    margin-left: 0.5rem;
    transition: transform 0.2s ease;
}

.get-started-btn:hover .arrow-icon {
    transform: translateX(4px);
}

.feature-cards {
    display: flex;
    justify-content: center;
    gap: 3rem;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.feature-card {
    flex: 1;
    max-width: 400px;
    background: white;
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;
    border: 2px solid transparent;
}

.feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    border-color: #6b46c1;
}

.card-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.card-header h2 {
    margin: 0;
    color: #2d3748;
    font-size: 1.8rem;
}

.feature-icon {
    font-size: 2rem;
    color: #6b46c1;
}

.card-body ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.card-body li {
    padding: 0.8rem 0;
    color: #4a5568;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: white;
    padding: 2rem;
    border-radius: 20px;
    width: 90%;
    max-width: 600px;
    position: relative;
    max-height: 80vh;
    overflow-y: auto;
}

.close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #4a5568;
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s ease;
}

.close-button:hover {
    color: #2d3748;
}

.feature-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-top: 2rem;
}

.feature-item {
    text-align: center;
    padding: 1.5rem;
    background: #f7fafc;
    border-radius: 15px;
    transition: all 0.3s ease;
}

.feature-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.feature-item h3 {
    margin: 1rem 0;
    color: #2d3748;
}

.feature-item p {
    color: #4a5568;
    line-height: 1.5;
}

.feature-item svg {
    font-size: 2rem;
    color: #6b46c1;
}

.begin-section {
    text-align: center;
    margin-top: 2rem;
}

.begin-button {
    background: linear-gradient(135deg, #6b46c1, #9f7aea);
    color: white;
    padding: 1rem 3rem;
    border: none;
    border-radius: 50px;
    font-size: 1.25rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(107, 70, 193, 0.2);
}

.begin-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(107, 70, 193, 0.3);
    background: linear-gradient(135deg, #553c9a, #805ad5);
}

.cta-section {
    text-align: center;
}

.cta-button {
    background-color: #6b46c1;
    color: white;
    padding: 1rem 2rem;
    border: none;
    border-radius: 4px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

.cta-button:hover {
    background-color: #553c9a;
}

.footer {
    width: 100%;
    text-align: center;
    padding-top: 500px;
    padding-bottom: 15px;
    font-size: 0.875rem;
    color: #6b7280;
    background-color: #e8d8f9;
    margin-top: 0px; /* Adjust this value to increase space */
}

.user-status {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    font-size: 1rem;
    color: #333;
    display: flex;
    align-items: center;
    gap: 10px; /* Space between text and button */
}

.user-status p {
    margin: 0;
}

.sign-out-button {
    padding: 8px 12px;
    font-size: 0.9rem;
    font-weight: bold;
    background-color: #1e90ff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sign-out-button:hover {
    background-color: #005ecb;
}

@media (max-width: 768px) {
    .feature-cards {
        flex-direction: column;
        align-items: center;
    }

    .feature-card {
        width: 100%;
    }

    .welcome-section {
        flex-direction: column;
        gap: 1rem;
    }

    .decorative-line {
        width: 80%;
        max-width: none;
    }

    .welcome-content h1 {
        font-size: 2rem;
    }

    .welcome-content p {
        font-size: 1rem;
    }
}
