.jobs-page {
    min-height: 100vh;
    background-color: #f8f9fa;
}

.jobs-hero {
    background: linear-gradient(135deg, #6b46c1 0%, #6b46c1 100%);
    padding: 4rem 0 6rem;
    margin-bottom: -4rem;
    color: white;
}

.jobs-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.jobs-header {
    text-align: center;
    margin-bottom: 2.5rem;
}

.jobs-header h1 {
    font-size: 2.75rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: white;
}

.jobs-header p {
    font-size: 1.25rem;
    color: rgba(255, 255, 255, 0.9);
    margin: 0;
}

.jobs-filters {
    background: white;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.filters-row {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
}

.title-filter,
.company-filter,
.industry-filter,
.days-filter {
    flex: 1;
    min-width: 200px;
}

.title-input,
.company-input,
.industry-input,
.days-input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 1rem;
    color: #333;
    background: white;
    transition: all 0.2s ease;
}

.title-input:focus,
.company-input:focus,
.industry-input:focus,
.days-input:focus {
    outline: none;
    border-color: #8a2be2;
    box-shadow: 0 0 0 2px rgba(138, 43, 226, 0.1);
}

.company-filter {
    position: relative;
    flex: 1;
    min-width: 200px;
}

.company-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-top: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.company-dropdown div {
    padding: 0.75rem 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
    color: #333;
}

.company-dropdown div:hover {
    background: rgba(138, 43, 226, 0.1);
    color: #8a2be2;
}

.industry-filter {
    position: relative;
    flex: 1;
    min-width: 200px;
}

.industry-input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 1rem;
    color: #333;
    background: white;
    transition: all 0.2s ease;
}

.industry-input:focus {
    outline: none;
    border-color: #8a2be2;
    box-shadow: 0 0 0 2px rgba(138, 43, 226, 0.1);
}

.industry-input::placeholder {
    color: #666;
}

.industry-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-top: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.industry-dropdown div {
    padding: 0.75rem 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
    color: #333;
}

.industry-dropdown div:hover {
    background: rgba(138, 43, 226, 0.1);
    color: #8a2be2;
}

.industry-select,
.days-select,
.sort-select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%238a2be2' d='M6 8.825L1.175 4 2.238 2.938 6 6.7l3.763-3.763L10.825 4z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    padding-right: 2.5rem;
    padding: 0.875rem 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 1rem;
    background: white;
    color: #333;
    transition: all 0.2s ease;
}

.industry-select:hover,
.days-select:hover,
.sort-select:hover {
    border-color: #8a2be2;
}

.industry-select:focus,
.days-select:focus,
.sort-select:focus {
    outline: none;
    border-color: #8a2be2;
    box-shadow: 0 0 0 2px rgba(138, 43, 226, 0.1);
}

.search-button {
    padding: 0.875rem 2rem;
    background: #8a2be2;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    white-space: nowrap;
    min-width: 140px;
}

.search-button:hover {
    background: #7126b5;
    transform: translateY(-1px);
}

.jobs-results {
    padding-top: 6rem;
}

.jobs-list {
    display: grid;
    gap: 1.5rem;
    padding-bottom: 2rem;
}

.job-card {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    transition: all 0.2s ease;
    border: 1px solid #eee;
}

.job-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-color: #8a2be2;
}

.job-card h3 {
    margin: 0 0 0.5rem 0;
    color: #1a1a1a;
    font-size: 1.25rem;
    font-weight: 600;
}

.job-card h4 {
    margin: 0 0 0.5rem 0;
    color: #444;
    font-size: 1.1rem;
}

.job-card p {
    margin: 0 0 1rem 0;
    color: #666;
}

.job-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

.job-meta span {
    color: #888;
}

.apply-button {
    padding: 0.5rem 1rem;
    background: #8a2be2;
    color: white;
    text-decoration: none;
    border-radius: 6px;
    font-size: 0.9rem;
    font-weight: 500;
    transition: all 0.2s ease;
}

.apply-button:hover {
    background: #7126b5;
    transform: translateY(-1px);
}

.load-more-button {
    display: block;
    margin: 2rem auto;
    padding: 0.875rem 2rem;
    background: transparent;
    color: #8a2be2;
    border: 2px solid #8a2be2;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.load-more-button:hover {
    background: rgba(138, 43, 226, 0.1);
}

.loading-spinner {
    text-align: center;
    padding: 2rem;
    color: #666;
}

.no-jobs-message {
    text-align: center;
    padding: 2rem;
    color: #666;
    font-size: 1.1rem;
    background: #f8f9fa;
    border-radius: 8px;
    margin: 1rem 0;
    border: 1px solid #e0e0e0;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
    padding: 1rem 0;
}

.pagination-button {
    padding: 0.5rem 1rem;
    background: #8a2be2;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.pagination-button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.pagination-button:not(:disabled):hover {
    background: #7126b5;
    transform: translateY(-1px);
}

.page-info {
    color: #666;
    font-size: 0.9rem;
}

.recommendation-toggle {
    margin-top: 20px;
    text-align: center;
}

.toggle-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.toggle-button:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .jobs-hero {
        padding: 3rem 0 5rem;
    }

    .jobs-header h1 {
        font-size: 2rem;
    }

    .jobs-header p {
        font-size: 1.1rem;
    }

    .jobs-filters {
        padding: 1rem;
    }

    .filters-row {
        flex-direction: column;
    }

    .title-filter,
    .company-filter,
    .industry-filter,
    .days-filter {
        width: 100%;
    }
}
