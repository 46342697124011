.analysis-section {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.analysis-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    gap: 1rem;
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 8px;
}

.version-controls {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
}

.version-selector {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    flex-grow: 1;
    max-width: 300px;
    background-color: white;
}

.delete-version-btn {
    padding: 0.5rem 1rem;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.delete-version-btn:disabled {
    background-color: #dc354580;
    cursor: not-allowed;
}

.new-analysis-btn {
    padding: 0.75rem 1.5rem;
    background-color: #6b46c1;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.3s;
}

.new-analysis-btn:hover:not(:disabled) {
    background-color: #553c9a;
}

.new-analysis-btn:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
}

.delete-confirm {
    margin-top: 1rem;
    padding: 1rem;
    background-color: #fff;
    border: 1px solid #dc3545;
    border-radius: 4px;
}

.delete-confirm p {
    margin: 0 0 1rem 0;
    color: #dc3545;
}

.delete-confirm-buttons {
    display: flex;
    gap: 1rem;
}

.delete-confirm-buttons button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.delete-confirm-buttons button:first-child {
    background-color: #dc3545;
    color: white;
}

.delete-confirm-buttons button:last-child {
    background-color: #6c757d;
    color: white;
}

.analysis-results {
    padding: 2rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-top: 2rem;
}

.markdown-content {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid #ddd;
}

.markdown-content h1, 
.markdown-content h2, 
.markdown-content h3 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
}

.markdown-content p {
    margin-bottom: 1rem;
    line-height: 1.6;
}

.markdown-content ul, 
.markdown-content ol {
    margin-bottom: 1rem;
    padding-left: 2rem;
}

.upload-prompt, .analyzing-message {
    text-align: center;
    padding: 2rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    margin: 2rem 0;
}

pre {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 4px;
    overflow-x: auto;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    white-space: pre-wrap;
}

.analyze-btn {
    padding: 0.75rem 1.5rem;
    background-color: #0d6efd;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    margin: 0 auto;
    display: block;
}

.analyze-btn:disabled {
    background-color: #0d6efd80;
    cursor: not-allowed;
}

.analysis-actions {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
}

.analysis-container {
    margin: 20px 0;
    width: 100%;
    text-align: center;
}

.analysis-container p {
    color: #666;
    font-size: 16px;
    margin: 20px 0;
}

.analysis-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin: 10px 0;
    transition: background-color 0.2s;
}

.analysis-button:hover {
    background-color: #0056b3;
}

.analyze-button {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin: 10px 0;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.analyze-button:hover {
    background-color: #218838;
}

.analyze-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

.analysis-dropdown {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 20px;
    margin-top: 10px;
    max-height: 500px;
    overflow-y: auto;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    text-align: left;
}

.analysis-content {
    font-size: 14px;
    line-height: 1.6;
    white-space: pre-wrap;
}

.analysis-content h1,
.analysis-content h2,
.analysis-content h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #333;
}

.analysis-content ul {
    margin: 10px 0;
    padding-left: 20px;
}

.analysis-content li {
    margin: 5px 0;
}

.loading-spinner {
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 10px auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.version-controls {
    display: flex;
    gap: 10px;
    margin: 10px 0;
    justify-content: center;
}

.version-button {
    background-color: #6c757d;
    color: white;
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.version-button:hover {
    background-color: #5a6268;
}

.version-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.confirm-dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.confirm-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;
}

.confirm-content h3 {
    margin: 0 0 1rem 0;
    color: #2d3748;
    font-size: 1.25rem;
}

.confirm-content p {
    margin: 0 0 1.5rem 0;
    color: #4a5568;
    line-height: 1.5;
}

.confirm-buttons {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
}

.confirm-yes {
    padding: 0.5rem 1rem;
    background-color: #6b46c1;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.3s;
}

.confirm-no {
    padding: 0.5rem 1rem;
    background-color: #e2e8f0;
    color: #4a5568;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.3s;
}

.confirm-yes:hover {
    background-color: #553c9a;
}

.confirm-no:hover {
    background-color: #cbd5e0;
}

.resume-analysis {
    position: relative;
}

.connection-search-button {
    width: 100%;
    padding: 12px 20px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.connection-search-button:hover {
    background-color: #0056b3;
}

.connection-search-button:active {
    background-color: #004085;
    transform: translateY(1px);
}

.tabs {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #e5e7eb;
    padding-bottom: 0.5rem;
}

.tab {
    padding: 0.5rem 1rem;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 1rem;
    color: #6b7280;
    transition: all 0.2s;
}

.tab:hover {
    color: #4b5563;
}

.tab.active {
    color: #3b82f6;
    border-bottom: 2px solid #3b82f6;
}

.recommendations-content {
    background-color: white;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.analysis-page-header {
    text-align: center;
    margin-bottom: 2rem;
    padding: 2rem;
    background: #ffffff;
    color: #6b46c1;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(107, 70, 193, 0.2);
}

.analysis-page-header h1 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #6b46c1;
}

.analysis-page-header p {
    font-size: 1.1rem;
    opacity: 0.9;
    color: #805ad5;
}

.loading-bar-container {
    margin: 2rem auto;
    max-width: 600px;
    text-align: center;
    position: relative;
    z-index: 100;
}

.loading-bar {
    height: 6px;
    background-color: #e2e8f0;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
}

.loading-progress {
    position: absolute;
    height: 100%;
    background: linear-gradient(90deg, #6b46c1 0%, #805ad5 50%, #6b46c1 100%);
    background-size: 200% 100%;
    animation: loading-animation 2s linear infinite;
    width: 100%;
}

.loading-message {
    margin-top: 1rem;
    color: #4a5568;
    font-size: 1.1rem;
    font-weight: 500;
}

@keyframes loading-animation {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

.no-resume-message {
    text-align: center;
    padding: 3rem;
    margin: 2rem auto;
    max-width: 600px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.no-resume-message .message-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.no-resume-message i {
    font-size: 3rem;
    color: #6b46c1;
    margin-bottom: 1rem;
}

.no-resume-message h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2d3748;
    margin: 0;
}

.no-resume-message p {
    font-size: 1.1rem;
    color: #4a5568;
    margin: 0;
}

.back-arrow {
    position: fixed;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    background: white;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.recommendation-count {
    text-align: center;
    margin: 1rem 0;
    font-size: 1.2rem;
    color: #4a5568;
    font-weight: 500;
}

.no-resume-container,
.no-analysis-container,
.no-recommendations-container {
    text-align: center;
    padding: 40px;
    margin: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message-header {
    color: #343a40;
    margin-bottom: 16px;
    font-size: 1.5rem;
}

.message-text {
    color: #6c757d;
    margin-bottom: 24px;
    font-size: 1.1rem;
}

.profile-link-button {
    background-color: #2196F3;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    display: inline-block;
    font-size: 1.1rem;
    transition: background-color 0.3s;
}

.profile-link-button:hover {
    background-color: #1976D2;
    text-decoration: none;
    color: white;
}
