/* Global styles */
body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    height: 100%;
    overflow: hidden;
}

/* Top section styles */
.top-section {
    position: relative;
    padding: 32px 32px 0;
    background-color: #f8f9fa;
}

.page-title {
    font-size: 32px;
    font-weight: 700;
    color: #1a1a1a;
    letter-spacing: -0.5px;
    line-height: 1.2;
    margin: 0;
}

.page-title span {
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: #666;
    margin-top: 4px;
}

.search-section {
    padding: 0 32px 8px;
    background-color: #f8f9fa;
    margin-bottom: 0;
}

.search-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px;
    background-color: #f8f9fa;
}

.search-input {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 1rem;
    width: 250px;
    background-color: #f8f9fa;
    transition: all 0.2s ease;
}

.search-input:focus {
    outline: none;
    border-color: #999;
    box-shadow: 0 0 0 2px rgba(0,0,0,0.1);
    width: 300px;
}

.search-results-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

/* Navbar styles */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: white;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar-title {
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
}

.navbar-links {
    display: flex;
    gap: 1.5rem;
}

.navbar-link {
    background: none;
    border: none;
    color: #333;
    font-size: 1.2rem;
    cursor: pointer;
    font-weight: 500;
    transition: color 0.2s;
}

.navbar-link:hover {
    color: #666;
}

.parent-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    width: 100%;
    height: calc(100vh - 180px);
    background-color: #f8f9fa;
    overflow: hidden;
    margin-top: 0;
    padding-top: 0;
}

.search-query-container {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    background-color: #f8f9fa;
    padding-left: 16px;
    padding-top: 117px;
    width: 300px;
    height: 100%;
    transition: all 0.3s ease;
    transform: translateX(-250px);
}

.search-query-container.collapsed {
    transform: translateX(-250px);
}

.search-query-container:hover,
.search-query-container:focus-within {
    transform: translateX(0);
}

.search-content-results-container {
    background-color: #f8f9fa;
    flex-grow: 1;
    padding: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 60px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    width: 100%;
}

.search-form-container {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease;
    width: 100%;
}

.search-form-container.collapsed {
    width: 250px;
    height: 60px;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
}

.search-form-container.collapsed form {
    display: none;
}

.search-form-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #eee;
    margin-bottom: 0;
}

.toggle-search-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    color: #333;
    transition: transform 0.3s ease;
}

.toggle-search-button:hover {
    transform: scale(1.1);
}

.toggle-search-button.collapsed {
    transform: rotate(180deg);
}

.search-header {
    padding: 16px 24px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.search-text {
    font-size: 1.25rem;
    font-weight: 500;
}

.highlight {
    font-weight: bold;
    color: #333;
}

.results-container {
    flex-grow: 1;
    background-color: #f8f9fa;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    max-height:100%;
    margin: 0;
    padding: 0;
    width: calc(100% - 16px);
}

.search-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 0;
}

.search-container.embedded {
  min-height: unset;
  padding: 0;
  background-color: transparent;
}

.search-container.embedded .parent-container {
  margin-top: 0;
  padding: 0;
}

.search-page {
    height: 100vh;
    background-color: #f8f9fa;
    padding: 2rem;
}

.search-container {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
}

.results-container {
    position: relative;
    min-height: 400px;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    color: #4A5568;
}

.error-message {
    text-align: center;
    color: #E53E3E;
    padding: 1rem;
    background-color: #FFF5F5;
    border-radius: 8px;
    margin: 1rem 0;
}

.results-table {
    background: #f8f9fa;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-top: 2rem;
}

.results-table table {
    width: 100%;
    border-collapse: collapse;
}

.results-table th {
    background-color: #F7FAFC;
    padding: 1rem;
    text-align: left;
    color: #4A5568;
    font-weight: 600;
    border-bottom: 2px solid #E2E8F0;
}

.results-table td {
    padding: 1rem;
    border-bottom: 1px solid #E2E8F0;
    color: #2D3748;
}

.results-table tr:hover {
    background-color: #F7FAFC;
}

.results-table tr:last-child td {
    border-bottom: none;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #f8f9fa;
}

.dropdown::-webkit-scrollbar {
    width: 8px;
}

.dropdown::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.dropdown::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.dropdown::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Animation for search results */
.search-results-container {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}

.search-results-container.visible {
    opacity: 1;
    transform: translateY(0);
}

/* Responsive design */
@media (max-width: 768px) {
    .navbar {
        padding: 0 16px;
    }

    .search-content-results-container {
        padding: 80px 16px 16px;
    }

    .search-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }

    .search-input {
        width: 100%;
    }
}