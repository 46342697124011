/* index.css or App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Reset and basic styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}

body {
    margin: 0;
    padding: 0;
    background-color: white;
    color: #000000;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.App {
    width: 100%;
}
