/* Overlay to cover the entire screen */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Popup content box */
.popup-content {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Button styling */
.popup-content button {
    margin-top: 15px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #0073b1; /* LinkedIn blue */
    color: white;
    font-size: 1rem;
    cursor: pointer;
}

.popup-content button:hover {
    background-color: #005582; /* Darker LinkedIn blue */
}

/* Cancel button */
.popup-content button:last-child {
    background-color: #f8f9fa; /* Light gray */
    color: black;
}

.popup-content button:last-child:hover {
    background-color: #e2e6ea; /* Darker gray */
}
