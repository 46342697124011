/* ProfileSetup.css */

/* Main container */
.profile-setup-container {
    display: flex;
    height: calc(100vh - 60px);
    position: relative;
}

.sidebar-slit {
    position: fixed;
    left: 40px;
    bottom: 20px;
    width: 32px;
    height: 32px;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    z-index: 1000;
}

/* Sidebar */
.left-sidebar {
    background-color: #ffffff;
    width: 250px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    position: relative;
    height: 100%;
    cursor: pointer;
}


/* Sidebar when closed */
.left-sidebar.closed {
    width: 40px; /* Increased width for better visibility */
    padding: 0;
    margin: 0;
    overflow: hidden;
    background: white; /* Subtle background to indicate it's a sidebar */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Keep shadow for depth */
    position: relative;
    transition: all 0.3s ease;
}

/* Add a toggle bar or indicator for the closed sidebar */
.left-sidebar.closed::after {
    content: '>'; /* Right arrow */
    position: relative;
    top: 50%;
    right: -6px;
    z-index: 1001;
    transform: translateY(-50%);
    font-size: 1.5rem;
    color: #6b46c1;
    background-color: #dadada;
    border: 1px solid #e2e8f0;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 9999;
}



.left-sidebar.closed:hover::after {
    color: white;
    background-color: #8a2be2;
    transform: translateY(-50%) scale(1.1);
}

.left-sidebar.closed:hover::after {
    background-color: #8a2be2; /* Slightly brighter hover color */
    transform: translateY(-50%) scale(1.1); /* Subtle hover effect */
}

.left-sidebar.closed .navigation-section,
.left-sidebar.closed .profile-nav-section,
.left-sidebar.closed .sidebar-toggle {
    display: none;
}

/* Toggle button */
.sidebar-toggle {
    position: absolute;
    top: 50%;
    right: -15px;
    z-index: 1001;
    background: white;
    border: 1px solid #e2e8f0;
    color: #8a2be2;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 8px;
    transition: all 0.3s ease;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar-toggle:hover {
    transform: scale(1.1);
    box-shadow: 3px 0 8px rgba(0, 0, 0, 0.2);
}

.sidebar-toggle svg {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}

.sidebar-toggle.open svg {
    transform: rotate(0deg);
}

.sidebar-toggle.closed {
    position: absolute;
    right: -30px;
    top: 20px;
    background: white;
    border: 1px solid #e2e8f0;
    color: #8a2be2;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 8px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.sidebar-toggle.closed:hover {
    transform: scale(1.1);
    box-shadow: 3px 0 8px rgba(0, 0, 0, 0.2);
}

/* Navigation section */
.navigation-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
}

.profile-nav-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-top: 1px solid #e2e8f0;
    padding-top: 10px;
    margin-top: auto;
}

.sidebar-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease;
    white-space: nowrap;
}

.sidebar-item:hover {
    background-color: #f0f0f0;
}

.sidebar-item.active {
    background-color: #8a2be2;
    color: white;
}

.sidebar-item svg {
    width: 20px;
}

/* Profile section */
.profile-section {
    border-top: 1px solid #e2e8f0;
    padding-top: 1rem;
}

.profile-header {
    margin-bottom: 1rem;
}

.profile-header h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #2d3748;
}

.profile-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.profile-field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.profile-field label {
    font-size: 0.875rem;
    color: #4a5568;
    font-weight: 500;
}

.profile-field input {
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    font-size: 0.875rem;
}

.profile-actions {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
}

.save-button {
    background-color: #6b46c1;
    color: white;
    padding: 0.5rem;
    border-radius: 0.375rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
}

.save-button:hover {
    background-color: #553c9a;
}

.delete-button {
    background-color: #e53e3e;
    color: white;
    padding: 0.5rem;
    border-radius: 0.375rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
}

.delete-button:hover {
    background-color: #c53030;
}

.tab-content {
    height: 100%;
    width: 100%;
    overflow: auto;
    background-color: #f8f9fa;
}

.profile-loading {
    color: #718096;
    text-align: center;
    padding: 1rem;
}

/* Resume section styles */
.resume-section {
    background-color: #f8fafc;
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin-top: 1rem;
}

.resume-section h3 {
    color: #2d3748;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.resume-actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.resume-actions p {
    color: #4a5568;
    font-size: 0.875rem;
}

.resume-buttons {
    display: flex;
    gap: 1rem;
}

.view-button {
    flex: 1;
    background-color: #4299e1;
    color: white;
    padding: 0.75rem;
    border-radius: 0.375rem;
    border: none;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.view-button:hover {
    background-color: #3182ce;
}

.upload-button {
    display: inline-block;
    background-color: #48bb78;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 0.375rem;
    cursor: pointer;
    font-weight: 500;
    text-align: center;
    transition: background-color 0.2s;
}

.upload-button:hover {
    background-color: #38a169;
}

/* Responsive design */
@media (max-width: 768px) {
    .left-sidebar {
        width: 240px;
    }
}

@media (max-width: 640px) {
    .profile-setup-container {
        flex-direction: column;
    }

    .left-sidebar {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #e2e8f0;
    }
}

/* Navbar styles */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 32px;
    background-color: #4a4a4a;
    color: white;
    z-index: 1000;
}

.navbar-title {
    font-size: 1.5rem;
    font-weight: 700;
    cursor: pointer;
}

.navbar-links {
    display: flex;
    gap: 1.5rem;
}

.navbar-link {
    background: none;
    border: none;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    font-weight: 500;
    transition: color 0.2s;
}

.navbar-link:hover {
    color: #ddd;
}

/* Footer styles */
/* Footer styling with space above */
.footer {
    width: 100%;
    text-align: center;
    padding-top: 500px;
    padding-bottom: 15px;
    font-size: 0.875rem;
    color: #6b7280;
    background-color: #e8d8f9;
    margin-top: auto; /* Adjust this value to increase space */
}

.verify-container button {
    width: 100%;
    padding: 12px 15px;
    margin: 15px 0;
    background: #1e90ff;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
}

.user-status {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    font-size: 1rem;
    color: #333;
    display: flex;
    align-items: center;
    gap: 10px; /* Space between text and button */
}

.sign-out-button {
    padding: 8px 12px;
    font-size: 0.9rem;
    font-weight: bold;
    background-color: #1e90ff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sign-out-button:hover {
    background-color: #005ecb;
}

.analytics-message {
    text-align: center;
    padding: 2rem;
    background-color: #f7f4ff;
    border-radius: 8px;
    margin: 1rem 0;
}

.analytics-content {
    padding: 1rem;
}

.analysis-actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.analysis-controls {
    display: flex;
    gap: 1rem;
}

.version-controls {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 0.5rem;
    background-color: #f7f4ff;
    border-radius: 6px;
}

.version-controls select {
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #6b46c1;
    background-color: white;
    color: #2d3748;
    font-size: 0.9rem;
    min-width: 200px;
}

.delete-version-button {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    background-color: #fff1f2;
    color: #e11d48;
    border: 1px solid #e11d48;
    transition: all 0.3s ease;
}

.delete-version-button:hover {
    background-color: #ffe4e6;
}

.delete-version-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    background-color: #f1f5f9;
    border-color: #94a3b8;
    color: #94a3b8;
}

.view-analysis-button {
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #f7f4ff;
    color: #6b46c1;
    border: 1px solid #6b46c1;
}

.view-analysis-button:hover {
    background-color: #ede9fe;
}

.view-analysis-button.active {
    background-color: #6b46c1;
    color: white;
}

.view-analysis-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.loading-state {
    text-align: center;
    padding: 2rem;
}

.loading-state p {
    margin-top: 1rem;
    color: #2c3e50;
}

.loading-spinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #6b46c1;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Markdown Styling */
.analysis-content ul {
    padding-left: 20px;
    margin: 10px 0;
}

.analysis-content li {
    margin: 5px 0;
}

.analysis-content p {
    margin: 10px 0;
}

.analysis-content strong {
    font-weight: 600;
    color: #333;
}

.analysis-content em {
    font-style: italic;
    color: #666;
}

/* Profile Tab Content */
.profile-tab-content {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: calc(100vh - 160px); /* Account for header and margins */
    overflow-y: auto;
}

.tab-content {
    height: 100%;
    overflow-y: auto;
    padding: 1rem;
    background: white;
}

.profile-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.profile-form h2 {
    color: #2d3748;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.profile-field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.profile-field label {
    font-size: 0.875rem;
    color: #4a5568;
    font-weight: 500;
}

.profile-field input {
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    font-size: 1rem;
    transition: border-color 0.2s;
}

.profile-field input:focus {
    outline: none;
    border-color: #6b46c1;
    box-shadow: 0 0 0 3px rgba(107, 70, 193, 0.1);
}

.profile-actions {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.save-button {
    flex: 1;
    background-color: #6b46c1;
    color: white;
    padding: 0.75rem;
    border-radius: 0.375rem;
    border: none;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.save-button:hover {
    background-color: #553c9a;
}

.delete-button {
    flex: 1;
    background-color: #e53e3e;
    color: white;
    padding: 0.75rem;
    border-radius: 0.375rem;
    border: none;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.delete-button:hover {
    background-color: #c53030;
}

.tab.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    background-color: #f0f0f0;
}

.tab.disabled:hover {
    background-color: #f0f0f0;
    transform: none;
}

.main-content {
    flex: 1;
    padding: 20px;
    transition: margin-left 0.3s ease;
    overflow-y: auto;
}

.left-sidebar.closed + .main-content {
    margin-left: 5px;
}